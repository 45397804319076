<template>
  <div class="wysiwyg">
    <p class="">
      點選箭頭，下有5種材質選項以及色彩設定，可自由點選合適的材質或是顏色設定地面。兩者僅能擇其一，如果不小心都選到，系統僅會呈現材質的部分。
    </p>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.link {
  @apply text-sm text-gray-400;
}
</style>
